import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = [ "item" ]

  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      filter: "input, select, textarea",
      preventOnFilter: false,
      onEnd: this.updateInputNames.bind(this)
    })

    // Add a draggable cursor to items
    this.itemTargets.forEach((item) => {
      item.style.cursor = "grab"
    })
  }

  disconnect() {
    this.sortable.destroy()
  }

  /* FUNCTIONS */

  updateInputNames() {
    const items = this.itemTargets

    items.forEach((item, index) => {
      const inputs = item.querySelectorAll("input, select, textarea")

      inputs.forEach((input) => {
        const name = input.getAttribute("name")

        // Replace the index in the name with the new order index
        const newName = name.replace(/\d+/, index)
        input.setAttribute("name", newName)
      })
    })
  }
}
