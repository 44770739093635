import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "frame" ]

  connect() {
    // Hide the frame. We will show the placeholder content when we start loading.
    this.frameTargets.forEach((frame) => {
      frame.style.display = "none"
    })
  }

  loadFrame(e) {
    const targetFrameId = e.currentTarget.dataset.frameId
    const turboFrame = this.frameTargets.find((frame) => frame.id === targetFrameId)

    // Show the placeholder before setting the src.
    if (turboFrame && !turboFrame.src) {
      turboFrame.style.display = "block"
      turboFrame.src = turboFrame.dataset.src
    }
  }
}
