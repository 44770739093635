import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = []

  navigate(e) {
    const { url, frame } = e.currentTarget.dataset

    if (url) {
      Turbo.visit(url, frame ? { frame } : undefined)
    } else {
      console.warn("Missing data-url on link")
    }
  }
}
