import { Controller } from "@hotwired/stimulus"

var row
var rowClasses
var currentId = 0

export default class extends Controller {
  static targets = [ "list", "row" ]

  initialize() {
    // Find the "template" row and its classes
    row = this.rowTarget.children[0]
    rowClasses = Array.from(row.classList).map(cls => `.${cls}`).join("")

    // Find the last instance of existing rows
    const rows = this.listTarget.querySelectorAll(rowClasses)
    const lastRow = rows[rows.length - 1]

    // If it exists, find the ID and add 1 to prepare for the next row
    if (lastRow) {
      const input = lastRow.querySelector("input")
      const result = input?.id.match(/_(\d+)$/);

      if (result && result[1]) {
        currentId = parseInt(result[1], 10) + 1
      }
    }
  }

  addRow() {
    // Find all select2 instances from the template row and destroy them so they won't cause issues when adding
    // I would like to do this during initialization, but it might run before select2 initialization...
    row.querySelectorAll("select[data-controller*='select2']").forEach((select) => {
      if ($(select).data("select2")) {
        $(select).select2("destroy")
      }
    })

    const newRow = row.cloneNode(true)

    // Update the ID and Name with the currentId and increment for the next
    newRow.querySelectorAll("input, select, textarea").forEach((input, _) => {
      input.name = input.name.replace(/\[-1\]/, `[${currentId}]`)
      input.id = input.id.replace(/-1$/, currentId)
    })

    currentId++

    newRow.classList.remove("hidden")
    this.listTarget.appendChild(newRow)
  }

  remove(e) {
    const rowToRemove = e.target.closest(rowClasses)
    rowToRemove.remove()
  }
}
