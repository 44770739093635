import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  /**
   * Clears all inputs from children of an element.
   * @example
   *   Select          - Set to default option.
   *   Select Multiple - Set all options to false.
   *   Text            - Set value to empty string.
   */
  clear() {
    const element = this.element
    const inputs = element.querySelectorAll("input, select, textarea")

    inputs.forEach(input => {
      if (input.tagName.toLowerCase() === "select") {
        if (input.multiple) {
          Array.from(input.options).forEach(option => {
            option.selected = false
          })
        } else {
          input.selectedIndex = 0
        }
      } else {
        input.value = ""
      }
    })
  }
}
